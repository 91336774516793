import React, { FC } from 'react';
import {
  ActionButtonUiTypes,
  BookingDTO,
} from '../../../../../../../../../../types';
import { ViewMoreDetails } from '../../../../../../../ActionsButtons/ViewMoreDetails';
import { VideoConference } from '../../../../../../../ActionsButtons/VideoConference';
import { classes } from './BookingBodyContentActionLinks.st.css';

export interface BookingBodyContentActionLinksProps {
  booking: BookingDTO;
}

const BookingBodyContentActionLinks: FC<BookingBodyContentActionLinksProps> = ({
  booking,
}) => {
  return (
    <div className={classes.root}>
      <ViewMoreDetails booking={booking} type={ActionButtonUiTypes.LINK} />
      <VideoConference booking={booking} type={ActionButtonUiTypes.LINK} />
    </div>
  );
};

export default BookingBodyContentActionLinks;
