import React, { FC } from 'react';
import {
  SectionNotification,
  SectionNotificationType,
} from 'wix-ui-tpa/cssVars';
import { Error } from '@wix/wix-ui-icons-common/on-stage';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { groupLimit } from '../../../../../../consts/limit';
import { LimitIndicatorDataHooks } from '../../../datahooks';
import { classes } from './LimitIndicator.st.css';

export const LimitIndicator: FC<{}> = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.root} data-hook={LimitIndicatorDataHooks.Main}>
      <SectionNotification
        className={classes.sectionNotification}
        data-hook={LimitIndicatorDataHooks.SectionNotification}
        type={SectionNotificationType.wired}
      >
        <SectionNotification.Icon icon={<Error />} />
        <SectionNotification.Text>
          {t(
            'app.my-bookings-widget.bookings-list.group-limit-indication-label',
            { limit: groupLimit },
          )}
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};
