export enum ActionsMenuType {
  RESCHEDULE = 'reschedule',
  REVIEW_FORM_SUBMISSION = 'review-form-submission',
  CANCEL = 'cancel',
  BOOK_AGAIN = 'book_again',
  DIVIDER = 'divider',
}

export interface ActionMenuConfiguration {
  type: ActionsMenuType;
  Component: React.ReactNode;
}

export enum ActionButtonUiTypes {
  MENU = 'menu',
  BUTTON = 'button',
  LINK = 'link',
}
