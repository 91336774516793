import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Delete } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import { classes as classesActionsMenu } from '../../ManageButton/ManageButton.st.css';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
  ModalType,
} from '../../../../../types';
import { ActionsButtonsDataHooks, CancelDataHooks } from '../../datahooks';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { CancellationModalProps } from '../../../../../core/ModalFacade/modals/CancellationModal';

export interface CancelProps extends ActionButtonProps {
  group: BookingsGroup;
}

export const Cancel: React.FC<CancelProps> = ({
  type,
  onActionClick,
  group,
}) => {
  const { t } = useTranslation();
  const {
    actions: { showModal },
  } = useApplicationContext();
  let content;

  if (type === ActionButtonUiTypes.MENU) {
    content = (
      <ActionsMenuLayout
        className={classesActionsMenu.actionMenuLayout}
        data-hook={ActionsButtonsDataHooks.CancelMenu}
      >
        <ActionsMenuLayout.Item
          onClick={() => {
            showModal<CancellationModalProps>(ModalType.Cancellation, {
              group,
            });
            onActionClick?.(ActionsMenuType.CANCEL);
          }}
          prefixIcon={<Delete />}
          content={t('app.my-bookings-widget.book-cancel.label')}
        />
      </ActionsMenuLayout>
    );
  }

  return <div data-hook={CancelDataHooks.Main}>{content}</div>;
};
