import React, { FC } from 'react';
import Text, { TextType } from '../../Components/Text';
import { classes, st } from './Header.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { HeaderDataHooks } from '../datahooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const Header: FC = () => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={HeaderDataHooks.Main}
    >
      <Text
        className={classes.title}
        data-hook={HeaderDataHooks.PageTitle}
        type={TextType.Title}
        tagName="h2"
      >
        {getContent({
          settings,
          settingsParam: settingsParams.membersAreaTitle,
        })}
      </Text>
      <Text
        data-hook={HeaderDataHooks.PageSubtitle}
        className={classes.subtitle}
        type={TextType.Subtitle}
        tagName="p"
      >
        {getContent({
          settings,
          settingsParam: settingsParams.membersAreaDescription,
        })}
      </Text>
    </div>
  );
};
