import React, { FC } from 'react';
import { BookingDTO } from '../../../../../../../../types';
import BookingBodyContentServiceName from './RowContents/BookingBodyContentServiceName';
import BookingBodyContentStaffInfo from './RowContents/BookingBodyContentStaffInfo';
import BookingBodyContentActionLinks from './RowContents/BookingBodyContentActionLinks';
import BookingBodyContentParticipantInfo from './RowContents/BookingBodyContentParticipantInfo';
import BookingBodyContentDuration from './RowContents/BookingBodyContentDuration';
import {
  classes as gridLaoutClasses,
  st,
} from '../../../../../../../../core/gridayout/styles.st.css';
import { classes } from './BookingBodyContentRow.st.css';
import { BookingBodyContentRowDataHooks } from '../../../../../datahooks';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { isCourse } from '../../../../../../../../utils/bookInfo';

export interface BookingBodyContentRowProps {
  booking: BookingDTO;
  withServiceName?: boolean;
}

const BookingBodyContentRow: FC<BookingBodyContentRowProps> = ({
  booking,
  withServiceName,
}) => {
  const { isMobile } = useEnvironment();
  const staffName = booking.staffInfo?.fullName;
  const isCourseBooking = isCourse(booking);

  return (
    <div className={classes.root}>
      <div
        className={st(gridLaoutClasses.container, classes.container)}
        data-hook={BookingBodyContentRowDataHooks.Main}
      >
        <div>
          {withServiceName && (
            <div data-hook={BookingBodyContentRowDataHooks.ServiceName}>
              <BookingBodyContentServiceName booking={booking} />
            </div>
          )}
          {staffName && <BookingBodyContentStaffInfo booking={booking} />}
          {!isCourseBooking && <BookingBodyContentDuration booking={booking} />}
          <BookingBodyContentParticipantInfo booking={booking} />
          <BookingBodyContentActionLinks booking={booking} />
        </div>
        {!isMobile && (
          <div data-hook={BookingBodyContentRowDataHooks.EmptyGridColumn}></div>
        )}
      </div>
    </div>
  );
};

export default BookingBodyContentRow;
