import React from 'react';
import { CancellationModal } from './modals/CancellationModal';
import { CannotRescheduleGroupModal } from './modals/CannotRescheduleGroupModal';
import { ModalType } from '../../types';
import SubmissionViewModal from './modals/SubmissionViewModal';

export const config = {
  [ModalType.Cancellation]: (props: any) => <CancellationModal {...props} />,
  [ModalType.CannotRescheduleGroup]: (props: any) => (
    <CannotRescheduleGroupModal {...props} />
  ),
  [ModalType.Submission]: (props: any) => <SubmissionViewModal {...props} />,
};
