import { FormattedSubmission } from '@wix/ambassador-forms-v4-submission/types';
import React, { FC } from 'react';
import Text, {
  TextType,
} from '../../../../../components/MyBookings/Components/Text';
import { classes, st } from './Submission.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { SubmissionDataHooks } from '../../../../../components/MyBookings/Widget/datahooks';

export interface SubmissionProps {
  submission: FormattedSubmission;
}

export const Submission: FC<SubmissionProps> = ({ submission }) => {
  const { isMobile } = useEnvironment();
  return (
    <div
      data-hook={SubmissionDataHooks.Main}
      className={st(classes.root, { isMobile })}
    >
      <Text
        data-hook={SubmissionDataHooks.Label}
        type={TextType.Secondary}
        className={classes.label}
      >
        {submission.label}
      </Text>
      <Text
        data-hook={SubmissionDataHooks.Value}
        type={TextType.Primary}
        className={classes.value}
      >
        {submission.value}
      </Text>
    </div>
  );
};
