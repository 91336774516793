import React, { FC } from 'react';
import { st, classes } from './App.st.css';
import { WidgetDataHooks } from '../datahooks';
import { Header } from '../Header';
import BookingsLayout from '../BookingsLayout';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ModalFacade } from '../../../../core/ModalFacade';
import Toast from '../../Components/Toast';

const App: FC = () => {
  const { isMobile } = useEnvironment();

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={WidgetDataHooks.MyBookingsMainWidget}
    >
      <Header />
      <BookingsLayout />
      <ModalFacade />
      <Toast />
    </div>
  );
};

export default App;
