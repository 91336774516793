import React, { useState } from 'react';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';
import { Calendar } from '@wix/wix-ui-icons-common/on-stage';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import Button from '../../../Components/Button';
import { classes, st } from './BookAgain.st.css';
import { classes as classesActionsMenu } from '../../ManageButton/ManageButton.st.css';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
} from '../../../../../types';
import { ActionsButtonsDataHooks, BookAgainDataHooks } from '../../datahooks';
import { handleWithStopPropagation } from '../../../../../utils';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';

export interface BookAgainProps extends ActionButtonProps {
  group: BookingsGroup;
}

export const BookAgain: React.FC<BookAgainProps> = ({
  group,
  onActionClick,
  type,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { actions } = useApplicationContext();
  const [loading, setLoading] = useState(false);
  let content;

  if (type === ActionButtonUiTypes.MENU) {
    const onBookAgainMenuClick = () => {
      onActionClick?.(ActionsMenuType.BOOK_AGAIN, {
        showManageButtonLoader: true,
      });
      actions.clickOnBookAgain({ group });
    };

    content = (
      <div>
        <ActionsMenuLayout
          className={classesActionsMenu.actionMenuLayout}
          data-hook={ActionsButtonsDataHooks.BookAgainMenu}
        >
          <ActionsMenuLayout.Item
            onClick={onBookAgainMenuClick}
            prefixIcon={<Calendar />}
            content={t('app.my-bookings-widget.book-another.button.label')}
          />
        </ActionsMenuLayout>
      </div>
    );
  }

  if (type === ActionButtonUiTypes.BUTTON) {
    const onBookAgainButtonClick = () => {
      onActionClick?.(ActionsMenuType.BOOK_AGAIN);
      setLoading(true);
      actions.clickOnBookAgain({ group });
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        onBookAgainButtonClick();
      }
    };

    content = (
      <div>
        <Button
          loading={loading}
          className={classes.bookAgainButton}
          data-hook={ActionsButtonsDataHooks.BookAgainButton}
          onClick={(e) => handleWithStopPropagation(e, onBookAgainButtonClick)}
          onKeyDown={(e) => handleWithStopPropagation(e, handleKeyDown)}
        >
          {t('app.my-bookings-widget.book-another.button.label')}
        </Button>
      </div>
    );
  }

  return (
    <div
      className={st(classes.root, {
        isMobile,
        buttonType: type,
      })}
      data-hook={BookAgainDataHooks.Main}
    >
      {content}
    </div>
  );
};
