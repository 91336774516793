import React, { FC } from 'react';
import Text, { TextType } from '../../../../../../../../Components/Text';
import { BookingDTO } from '../../../../../../../../../../types';
import { classes } from './BookingBodyContentServiceName.st.css';
import { BookingBodyContentServiceNameDataHooks } from '../../../../../../../datahooks';

export interface BookingBodyContentServiceNameProps {
  booking: BookingDTO;
}

const BookingBodyContentServiceName: FC<BookingBodyContentServiceNameProps> = ({
  booking,
}) => {
  return (
    <div
      className={classes.root}
      data-hook={BookingBodyContentServiceNameDataHooks.Main}
    >
      <Text
        data-hook={BookingBodyContentServiceNameDataHooks.Text}
        type={TextType.Primary}
      >
        {booking.service?.name}
      </Text>
    </div>
  );
};

export default BookingBodyContentServiceName;
