export enum MemberAreaClickActions {
  EmptyStateButton = 'empty-state-button',
  ViewMoreDetails = 'view-more-details',
  BookAgain = 'book-again',
  Reschedule = 'reschedule',
  Cancel = 'cancel',
  OpenZoomLink = 'open-zoom-link',
  CancellationFeeModalClick = 'cancellation-fee-uou-modal-click',
  ViewPolicy = 'view-policy',
  ViewFormSubmission = 'view_form_submission',
}

export enum CancelModalClickValues {
  Reschedule = 'reschedule-bookings',
  Cancel = 'cancel-bookings',
  ExitModal = 'exit-modal',
  KeepMyBooking = 'keep-my-booking',
}

export enum ConferencePlatform {
  ZOOM = 'zoom',
}

export enum CancelClickOrigin {
  CancellationFeeModal = 'camcellation-fee-modal',
}

export enum CancellationFeeModalElements {
  CANCELLATION_FEE_UOU = 'cancellation_fee_uou',
}

export enum CancellationFeeModalValues {
  CANCEL = 'cancel',
  KEEP = 'keep',
  CLOSE = 'close',
  POLICY = 'policy',
}

export enum CancellationFeeModalComponents {
  MODAL = 'modal',
}

export enum CancellationFeeModalActions {
  load = 'load',
}
