import {
  VisitorLogger,
  ControllerFlowAPI,
  useVisitorBi,
} from '@wix/yoshi-flow-editor';
import { ApplicationState } from '../../types';
import { Api } from '../../api';
import { widgetDefaults } from './consts';

export interface BiLogger extends VisitorLogger {
  update: (state: ApplicationState) => void;
}

const updateDefaultBiParams = (
  viewerBiLogger: VisitorLogger,
  state: ApplicationState,
) => {
  const mapStateToDefaultBiParams = () => {
    return {
      pageName: widgetDefaults.pageName,
    };
  };

  viewerBiLogger?.updateDefaults?.(mapStateToDefaultBiParams());
};

export function createBiLogger({
  flowAPI,
  api,
}: {
  flowAPI: ControllerFlowAPI;
  api: Api;
}): BiLogger | undefined {
  const { bi: viewerBiLogger } = flowAPI;
  const shouldInitializeBiLogger = viewerBiLogger && !api.isSSR();

  if (!shouldInitializeBiLogger) {
    return undefined;
  }

  return Object.assign(viewerBiLogger, {
    update: (state: ApplicationState) =>
      updateDefaultBiParams(viewerBiLogger, state),
  }) as BiLogger;
}

export function useBiLogger(state: ApplicationState): BiLogger {
  const viewerBiLogger = useVisitorBi();

  updateDefaultBiParams(viewerBiLogger, state);

  if (state?.environmentModes?.isSSR) {
    // do not report if it is SSR
    return Object.assign(viewerBiLogger, {
      report: async (p: any) => {},
    }) as BiLogger;
  }

  return viewerBiLogger as BiLogger;
}
