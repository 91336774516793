import React, { FC } from 'react';
import { Toast as TpaToast } from 'wix-ui-tpa/cssVars';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { classes, st } from './Toast.st.css';
import { useApplicationContext } from '../../../../core/hooks/useApplicationContext';
import { ToastDataHooks } from '../../Widget/datahooks';

const Toast: FC = () => {
  const { isMobile } = useEnvironment();
  const { actions, toastContext } = useApplicationContext();

  if (!toastContext) {
    return null;
  }

  const { skin, content } = toastContext || {};

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={ToastDataHooks.Main}
    >
      <TpaToast
        data-hook={ToastDataHooks.TpaToast}
        skin={skin}
        shouldShowCloseButton
        onClose={() => actions.closeToast()}
        className={classes.toast}
      >
        {content}
      </TpaToast>
    </div>
  );
};

export default Toast;
