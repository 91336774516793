import React, { FC } from 'react';
import { Spinner } from 'wix-ui-tpa';
import { ModalDataHooks } from '../../Widget/datahooks';
import { st, classes } from './CenteredSpinner.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const CenteredSpinner: FC<{}> = () => {
  const { isMobile } = useEnvironment();
  return (
    <div className={st(classes.spinnerWrapper, { isMobile })}>
      <Spinner isCentered data-hook={ModalDataHooks.Spinner} />
    </div>
  );
};
