import React, { FC, useState } from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import Button from '../../../Components/Button';
import { classes, st } from './EmptyState.st.css';
import { getContent } from '../../../../../utils/content/content';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { EmptyStateDataHooks } from '../../datahooks';
import Text, { TextType } from '../../../Components/Text';
import settingsParams from '../../../settingsParams';
import { TabTypes } from '../../../../../types';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export const EmptyState: FC = () => {
  const { selectedTab, actions } = useApplicationContext();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const [loading, setLoading] = useState(false);

  const noBookingsSettingsParamText =
    selectedTab.type === TabTypes.UPCOMING
      ? settingsParams.membersAreaUpcomingEmptyText
      : settingsParams.membersAreaHistoryEmptyText;
  const noBookingsSettingsParamButtonText =
    selectedTab.type === TabTypes.UPCOMING
      ? settingsParams.membersAreaUpcomingEmptyLink
      : settingsParams.membersAreaHistoryEmptyLink;

  const onEmptyStateButtonClick = () => {
    setLoading(true);
    actions.clickOnEmptyStateButton();
  };

  return (
    <div
      className={st(classes.root, { isMobile })}
      data-hook={EmptyStateDataHooks.Main}
    >
      <div
        className={classes.content}
        data-hook={
          selectedTab.type === TabTypes.UPCOMING
            ? EmptyStateDataHooks.UpcomingMain
            : EmptyStateDataHooks.HistoryMain
        }
      >
        <Text
          className={classes.text}
          data-hook={EmptyStateDataHooks.Text}
          type={TextType.Subtitle}
          tagName="p"
        >
          {getContent({
            settings,
            settingsParam: noBookingsSettingsParamText,
          })}
        </Text>
        <Button
          loading={loading}
          className={classes.button}
          data-hook={EmptyStateDataHooks.Button}
          onClick={onEmptyStateButtonClick}
        >
          {getContent({
            settings,
            settingsParam: noBookingsSettingsParamButtonText,
          })}
        </Button>
      </div>
    </div>
  );
};
