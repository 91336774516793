import React, { FC } from 'react';
import {
  ActionButtonProps,
  ActionButtonUiTypes,
  ActionsMenuType,
  BookingsGroup,
  MemberAreaClickActions,
  ModalType,
} from '../../../../../types';
import ListItem from '../../../../../assets/icons/list-item.svg';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes as classesActionsMenu } from '../../ManageButton/ManageButton.st.css';
import { useApplicationContext } from '../../../../../core/hooks/useApplicationContext';
import { SubmissionViewModalProps } from '../../../../../core/ModalFacade/modals/SubmissionViewModal';
import { ActionsMenuLayout } from 'wix-ui-tpa/cssVars';
import {
  ActionsButtonsDataHooks,
  ReviewFormSubmissionDataHooks,
} from '../../datahooks';
import { useBiLogger } from '../../../../../core/bi/biLoggerFactory';
import { bookingsLoginUserAccountSeeAllServicesClick } from '@wix/bi-logger-bookings-data/v2';

export interface ReviewFormSubmissionProps extends ActionButtonProps {
  group: BookingsGroup;
}

export const ReviewFormSubmission: FC<ReviewFormSubmissionProps> = ({
  group,
  type,
  onActionClick,
}) => {
  const { t } = useTranslation();
  const state = useApplicationContext();
  const { actions, selectedTab } = state;
  const biLogger = useBiLogger(state);

  let content;

  if (type === ActionButtonUiTypes.MENU) {
    content = (
      <ActionsMenuLayout
        className={classesActionsMenu.actionMenuLayout}
        data-hook={ActionsButtonsDataHooks.ReviewFormSubmission}
      >
        <ActionsMenuLayout.Item
          content={t(
            'app.my-bookings-widget.review-form-submission-button.label',
          )}
          onClick={() => {
            actions.showModal<SubmissionViewModalProps>(ModalType.Submission, {
              group,
            });
            biLogger?.report(
              bookingsLoginUserAccountSeeAllServicesClick({
                action: MemberAreaClickActions.ViewFormSubmission,
                referralInfo: selectedTab.type,
              }),
            );
            onActionClick?.(ActionsMenuType.REVIEW_FORM_SUBMISSION);
          }}
          prefixIcon={<ListItem />}
        />
      </ActionsMenuLayout>
    );
  }

  return <div data-hook={ReviewFormSubmissionDataHooks.Main}>{content}</div>;
};
