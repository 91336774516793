import { ActionButtonUiTypes, ActionsMenuType } from '../ManageButton';

export interface ActionButtonProps {
  type: ActionButtonUiTypes;
  onActionClick?: OnActionClickType;
}

export type ActionButtonOptions = {
  showManageButtonLoader?: boolean;
};

export type OnActionClickType = (
  type: ActionsMenuType,
  options?: ActionButtonOptions,
) => void;

export enum RescheduleButtonOrigin {
  ActionMenu = 'action-menu',
  CancellationModal = 'cancellation-modal',
  CancellationFeeModal = 'cancellation-fee-modal',
}
