import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';

export type ISettingsParams = {
  membersAreaTitle: SettingsParamType.Text;
  membersAreaDescription: SettingsParamType.Text;
  membersAreaUpcomingText: SettingsParamType.Text;
  membersAreaHistoryText: SettingsParamType.Text;
  membersAreaUpcomingEmptyText: SettingsParamType.Text;
  membersAreaUpcomingEmptyLink: SettingsParamType.Text;
  membersAreaHistoryEmptyText: SettingsParamType.Text;
  membersAreaHistoryEmptyLink: SettingsParamType.Text;
};

export default createSettingsParams<ISettingsParams>({
  membersAreaTitle: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.header.title'),
  },
  membersAreaDescription: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.header.subtitle'),
  },
  membersAreaUpcomingText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.upcoming-tab.label'),
  },
  membersAreaHistoryText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) => t('app.my-bookings-widget.history-tab.label'),
  },
  membersAreaUpcomingEmptyText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.my-bookings-widget.empty-state.no-upcoming-bookings.label'),
  },
  membersAreaUpcomingEmptyLink: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.my-bookings-widget.empty-state.no-upcoming-bookings.button.label'),
  },
  membersAreaHistoryEmptyText: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.my-bookings-widget.empty-state.no-history-bookings.label'),
  },
  membersAreaHistoryEmptyLink: {
    type: SettingsParamType.Text,
    getDefaultValue: ({ t }) =>
      t('app.my-bookings-widget.empty-state.no-history-bookings.button.label'),
  },
});
