import React from 'react';
import {
  BookingPolicyModal,
  Policy,
} from '@wix/bookings-checkout-common-components';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { CancellationModalDataHooks } from '../../../../../../components/MyBookings/Widget/datahooks';
import { classes } from './PolicyModal.st.css';

export interface BookingPolicyModalContentProps {
  policies: Policy[];
  onClose: () => void;
}

export const BookingPolicyModalContent: React.FC<
  BookingPolicyModalContentProps
> = ({ policies, onClose }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const handleCloseButtonClick = () => {
    onClose();
  };

  return (
    <div className={classes.root}>
      <BookingPolicyModal
        dataHook={CancellationModalDataHooks.BookingPolicy}
        className={classes.modal}
        modalTitle={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.title',
          {
            numOfPolicies: policies.length,
          },
        )}
        isOpen
        isMobile={isMobile}
        policies={policies}
        onClose={handleCloseButtonClick}
        onPrimaryButtonClick={handleCloseButtonClick}
        primaryButtonText={t(
          'app.my-bookings-widget.booking-policy-modal.primary-button.label',
        )}
      />
    </div>
  );
};
