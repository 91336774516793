import React, { FC, useCallback, useEffect, useState } from 'react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import {
  TabbedContent,
  TabsAlignment,
  Spinner,
  SpinnerTypes,
} from 'wix-ui-tpa/cssVars';
import { classes, st } from './BookingsLayout.st.css';
import { debounce } from 'lodash';
import {
  ApplicationContext,
  useApplicationContext,
} from '../../../../core/hooks/useApplicationContext';
import { ApplicationStatus, TabTypes } from '../../../../types';
import { ControllerActions } from '../../../../actions/actions';
import { BookingsList } from './BookingsList';
import { TimezoneDropdown } from '../TimezoneDropdown';
import { useSettings } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import { getContent } from '../../../../utils/content/content';
import settingsParams from '../../settingsParams';
import { BookingsLayoutDataHooks } from '../datahooks';
import { EmptyState } from './EmptyState';
import { type PagingMetadataV2 } from '@wix/ambassador-bookings-reader-v2-extended-booking/types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ExperimentsConsts } from '../../../../consts/experiments';

type ITabConfig = {
  [key in TabTypes]: {
    tabIndex: number;
    id: string;
    title: (settings: ISettingsContextValue) => string;
    isDataLoaded: (data: ApplicationContext) => boolean;
    isEmpty: (data: ApplicationContext) => boolean;
    getData: (actions: ControllerActions) => void;
    getPagingMetaData: (data: ApplicationContext) => PagingMetadataV2;
  };
};

const TabConfig: ITabConfig = {
  [TabTypes.UPCOMING]: {
    tabIndex: 0,
    id: TabTypes.UPCOMING,
    title: (settings: ISettingsContextValue) =>
      getContent({
        settings,
        settingsParam: settingsParams.membersAreaUpcomingText,
      }),
    isDataLoaded: ({ upcomingData }) => Boolean(upcomingData),
    isEmpty: ({ upcomingData }) => !upcomingData?.groups.length,
    getData: (actions: ControllerActions) => actions.getUpcoming(),
    getPagingMetaData: ({ upcomingData }) => upcomingData?.pagingMetadata!,
  },
  [TabTypes.HISTORY]: {
    tabIndex: 1,
    id: TabTypes.HISTORY,
    title: (settings: ISettingsContextValue) =>
      getContent({
        settings,
        settingsParam: settingsParams.membersAreaHistoryText,
      }),
    isDataLoaded: ({ historyData }) => Boolean(historyData),
    isEmpty: ({ historyData }) => !historyData?.groups.length,
    getData: (actions: ControllerActions) => actions.getHistory(),
    getPagingMetaData: ({ historyData }) => historyData?.pagingMetadata!,
  },
};

const BookingsLayout: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const applicationContext = useApplicationContext();
  const { experiments } = useExperiments();
  const isMembersAreaLazyLoadEnabled = experiments.enabled(
    ExperimentsConsts.MembersAreaLazyLoad,
  );
  const { actions, selectedTab, selectedTimezone, historyData, upcomingData } =
    applicationContext;
  const [tabIndex, setTabIndex] = useState(
    TabConfig[selectedTab.type].tabIndex,
  );

  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();

  const pagingMetaData =
    TabConfig[selectedTab.type].getPagingMetaData(applicationContext);

  useEffect(() => {
    if (
      applicationContext.status === ApplicationStatus.IDLE &&
      !TabConfig[selectedTab.type].isDataLoaded(applicationContext)
    ) {
      setIsLoading(true);
      TabConfig[selectedTab.type].getData(actions);
    } else if (selectedTimezone?.type && selectedTab?.type) {
      setIsLoading(false);
    }
  }, [
    applicationContext.status,
    selectedTimezone?.type,
    selectedTab.type,
    historyData,
    upcomingData,
  ]);

  const handleOnTabClick = (id: number) => {
    const selectedTabType: TabTypes = Object.keys(TabConfig).find(
      (key: string) => TabConfig[key as TabTypes].tabIndex === id,
    ) as TabTypes;

    setTabIndex(id);
    actions.setSelectedTab({ type: selectedTabType });
  };

  const getItems = useCallback(() => {
    return [
      {
        id: TabConfig[TabTypes.UPCOMING].id,
        title: TabConfig[TabTypes.UPCOMING].title(settings),
      },
      {
        id: TabConfig[TabTypes.HISTORY].id,
        title: TabConfig[TabTypes.HISTORY].title(settings),
      },
    ];
  }, []);

  const renderLoader = () => {
    return (
      <div className={classes.listSpinnerWrapper}>
        <Spinner
          type={SpinnerTypes.slim}
          className={classes.spinner}
          data-hook={BookingsLayoutDataHooks.ListSpinner}
          isCentered={true}
          diameter={24}
        />
      </div>
    );
  };

  const getMoreItems = useCallback(
    debounce(() => {
      TabConfig[selectedTab.type].getData(actions);
    }, 500),
    [upcomingData, historyData],
  );

  const getListLength = () => {
    return selectedTab.type === TabTypes.UPCOMING
      ? upcomingData?.groups.length!
      : historyData?.groups.length!;
  };

  return (
    <div
      data-hook={BookingsLayoutDataHooks.Main}
      className={st(classes.root, { isMobile })}
    >
      <TabbedContent
        data-hook={BookingsLayoutDataHooks.TabbedContent}
        className={classes.tabbedContent}
        activeTabIndex={tabIndex}
        onTabClick={handleOnTabClick}
        alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
        items={getItems()}
      >
        {() => {
          if (isLoading) {
            return (
              <div className={classes.spinnerWrapper}>
                <Spinner
                  className={classes.spinner}
                  data-hook={BookingsLayoutDataHooks.Spinner}
                  diameter={50}
                  isCentered={true}
                />
              </div>
            );
          }

          if (TabConfig[selectedTab.type].isEmpty(applicationContext)) {
            return <EmptyState />;
          }

          return (
            <div className={classes.content}>
              <TimezoneDropdown />
              {isMembersAreaLazyLoadEnabled ? (
                <InfiniteScroll
                  dataLength={getListLength()}
                  next={getMoreItems}
                  hasMore={pagingMetaData?.hasNext!}
                  loader={renderLoader()}
                  pullDownToRefreshThreshold={50}
                  style={{ overflow: 'unset' }}
                >
                  <BookingsList />
                </InfiniteScroll>
              ) : (
                <BookingsList />
              )}
            </div>
          );
        }}
      </TabbedContent>
    </div>
  );
};

export default BookingsLayout;
