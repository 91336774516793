import { useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { CannotRescheduleGroupModalDataHooks } from '../../../../components/MyBookings/Widget/datahooks';
import { Modal } from '../../../../components/MyBookings/Components/Modal';
import { useApplicationContext } from '../../../hooks/useApplicationContext';

export const CannotRescheduleGroupModal: React.FC = () => {
  const { actions } = useApplicationContext();
  const { t } = useTranslation();

  return (
    <div data-hook={CannotRescheduleGroupModalDataHooks.Main}>
      <Modal
        dataHook={CannotRescheduleGroupModalDataHooks.Modal}
        modalTitle={t('app.my-bookings-widget.reschedule-modal.title')}
        modalSubtitle={t('app.my-bookings-widget.reschedule-modal.content')}
        primaryButton={{
          context: {
            label: t(
              'app.my-bookings-widget.reschedule-modal.primary-button.label',
            ),
            onClick: () => actions.closeModal({}),
          },
        }}
      />
    </div>
  );
};
