import React, { FC } from 'react';
import Text, { TextType } from '../../../../../../../../Components/Text';
import { BookingDTO } from '../../../../../../../../../../types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { BookingBodyContentParticipantInfoDataHooks } from '../../../../../../../datahooks';

export interface BookingBodyContentParticipantInfoProps {
  booking: BookingDTO;
}

const BookingBodyContentParticipantInfo: FC<
  BookingBodyContentParticipantInfoProps
> = ({ booking }) => {
  const { t } = useTranslation();

  const renderContent = () => {
    if (booking.dynamicPriceInfo?.custom) {
      return booking.dynamicPriceInfo.custom
        .split(',')
        .map((info) => <div>{info}</div>);
    } else if (booking.numOfParticipants && booking.numOfParticipants > 1) {
      return t(
        'app.my-bookings-widget.booking-content.number-of-participants-prefix',
        { numOfParticipants: booking.numOfParticipants },
      );
    }
  };

  const content = renderContent();

  if (!content) {
    return null;
  }

  return (
    <div data-hook={BookingBodyContentParticipantInfoDataHooks.Main}>
      <Text
        data-hook={BookingBodyContentParticipantInfoDataHooks.Text}
        type={TextType.Secondary}
      >
        {content}
      </Text>
    </div>
  );
};

export default BookingBodyContentParticipantInfo;
