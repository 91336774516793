import { useCallback } from 'react';
import { useApplicationContext } from '../useApplicationContext';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

export enum TimezoneFormatType {
  NAME = 'name`',
  OFFSET = 'offset',
}

export interface Formatters {
  getStartTime: (start: Date) => string;
  getStartDate: (start: Date) => string;
  getTimezoneName: (
    timeZoneFormatType: TimezoneFormatType,
    overrideTimezone?: string,
  ) => string | undefined;
  getDuration: (start: Date, end: Date) => string;
}

export const useFormatters = (): Formatters => {
  const { selectedTimezone } = useApplicationContext();
  const { timezone } = selectedTimezone || {};

  const { t } = useTranslation();
  const { language } = useEnvironment();

  const createIntl = (options?: Intl.DateTimeFormatOptions) => {
    return new Intl.DateTimeFormat(language, {
      timeZone: timezone,
      ...options,
    });
  };

  const getStartTime = useCallback(
    (start: Date) => {
      return createIntl({ hour: 'numeric', minute: 'numeric' }).format(start);
    },
    [timezone, language],
  );

  const getStartDate = useCallback(
    (start: Date) => {
      return createIntl({
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }).format(start);
    },
    [timezone, language],
  );

  const getTimezoneName = useCallback(
    (timeZoneFormatType: TimezoneFormatType, overrideTimezone?: string) => {
      const options = {
        year: 'numeric',
        timeZoneName:
          timeZoneFormatType === TimezoneFormatType.NAME ? 'long' : 'short',
        ...(overrideTimezone ? { timeZone: overrideTimezone } : {}),
      } as Intl.DateTimeFormatOptions;

      return createIntl(options)
        .formatToParts(new Date())
        .find(({ type }) => type === 'timeZoneName')?.value;
    },
    [timezone, language],
  );

  const getDuration = useCallback((start: Date, end: Date) => {
    const duration = end.getTime() - start.getTime();
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor(duration / (1000 * 60 * 60)) % 24;
    const minutes = Math.floor((duration / (1000 * 60)) % 60);

    const result = [];

    if (days > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.days', {
          days,
        }),
      );
    }

    if (hours > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.hours', {
          hours,
        }),
      );
    }

    if (minutes > 0) {
      result.push(
        t('app.my-bookings-widget.bookings-details.duration.minutes', {
          minutes,
        }),
      );
    }

    return result.join(' ');
  }, []);

  return {
    getStartTime,
    getStartDate,
    getTimezoneName,
    getDuration,
  };
};
